<template>
   <div
      style="padding-left: 22px; padding-right: 22px; box-sizing: border-box;"
      class="app-container grey lighten-5 v-sheet v-sheet--shaped mt-4" light>
      <v-card
         v-show="!systemDesign"
         class="app-container v-sheet v-sheet--shaped grey lighten-5 mt-4  mb-8" light>
         <v-row space-around>
            <v-col class="d-inline">
               <v-card-title>Systems</v-card-title>
               <SiteSelect :siteslist.sync='siteslist' :selectedSite.sync='selectedSite'/>
            </v-col>
            <v-col class="d-flex justify-end">
               <v-btn color="primary" class="mr-4" @click="createItem">
                  <v-icon left>
                     mdi-plus-circle
                  </v-icon>
                  Create new System
               </v-btn>
            </v-col>
         </v-row>
      </v-card>

      <v-card class="d-flex flex-wrap mh-500 grey lighten-5 ">
         <div class="midhint" v-if="!selectedSite">Please choose a site to view it's systems</div>
         <div class="midhint" v-if="selectedSite && systemsList.length < 1">This site is empty</div>
         <v-card
            v-for="(n, index) in systemsList"
            :key="'_k'+index"
            class="rounded ma-3 pa-4"
            light elevation="2" width="340">
            <v-img :src="n.file_thumb" height="200"></v-img>
            <v-card-title>
               {{n.id}} | {{n.title}}
            </v-card-title>
            <v-card-text>
               <v-row align="center" class="mx-0">
                  <v-card-subtitle>
                     location: {{n.location}}
                  </v-card-subtitle>
                  <div>{{ n.short_desc }}</div>
               </v-row>
            </v-card-text>
            <v-icon class="editbutton" @click="editSystem(n)">mdi-pencil</v-icon>
            <v-icon class="editbutton2" @click="editSvg(n)">mdi-label</v-icon>
            <v-switch
               class="pl-3"
               v-model="n.is_active"
               :label="`${togtitle(n, n.is_active)}`"
               @click="changeActiveStatus(n)"
            ></v-switch>
         </v-card>
      </v-card>

      <PointCloudViewer  @gototag="gototag" />

      <SystemForm
         :systemItem="item"
         @update-value="updateData"/>

      <svgEditor
         :svgItem="systemDesign"
         @close-svg-editor="closeSvgEditor"
      />

      <!-- <SystemView /> -->
   </div>
</template>

<script>
import { auth } from '@/main';
import System from '@/models/System.js';
import SystemService from '@/service/SystemService.js';
import SystemForm from '@/components/SystemForm.vue';
import svgEditor from '@/components/svgEditor.vue';
import util from '@/utils/';
import SiteSelect from '@/components/SiteSelect.vue';
import PointCloudViewer from '@/components/PointCloudViewr';

export default {
   components: {
      SystemForm,
      svgEditor,
      SiteSelect,
      PointCloudViewer,
   },
   watch: {
      selectedSite: function (site) {
         if (this.item) {
            this.item = null;
         }
         this.callSystemServiceListBySite(site);
      },
      siteslist: function(list) {
         if (typeof (this.$route.query.site_id) === 'string') {
            this.selectedSite = list.find((obj) => obj.id == this.$route.query.site_id);
         }
      },
   },
   data() {
      return {
         auth: auth,
         show: false,
         total: 0,
         systemsList: [],
         siteslist: [],
         item: null,
         selectedSite: null,
         systemDesign: null,
         item2: null,
         showhint: false,
      };
   },
   mounted() {
      this.resetView();
   },
   created() {
      this.resetView();
   },
   methods: {
      gototag(pointCloudTag) {
         console.log('gototag', pointCloudTag);
         // systemsList
         // const systemTag = this.systemsList.find((obj) => obj.id === tagid);
         this.editSvg(pointCloudTag.system_tag.system);
         this.$store.dispatch('storeIframeUrl', null);
      },
      resetView() {
         console.log('resetting view - ', typeof (this.$route.query.site_id));
         this.$store.dispatch('storeIframeUrl', null);
         this.systemDesign = null;
      },
      closePointCloud() {
         if (this.showhint) {
            this.showhint = false;
         } else {
            this.$store.dispatch('storeIframeUrl', null);
         }
      },
      closeSvgEditor() {
         this.systemDesign = null;
      },
      updateData(data) {
         this.item = data;
         this.callSystemServiceListBySite(this.selectedSite);
      },
      editSystem(sitedata) {
         this.item = sitedata;
      },
      editSvg(n) {
         this.systemDesign = n;
      },
      createItem() {
         if (!this.selectedSite) {
            util.showMessage('Please choose a site first', 'error');
            return;
         }
         this.item = new System();
         this.item.site_id = this.selectedSite.id;
      },
      togtitle(n, stat) {
         if (stat) {
            return 'active';
         }
         return 'inactive';
      },
      changeActiveStatus(n) {
         const systemService = new SystemService(this);
         systemService.update(n.id, n);
      },
      clicksite(siteid) {
         alert(siteid);
      },
      callSystemServiceListBySite(site) {
         this.loading = true;
         const systemService = new SystemService(this);
         systemService.list(site.id).then((list) => {
            if (list) {
               this.systemsList = list.data;
               // this.editSvg(this.systemsList[0]);
            }
            this.loading = false;
         });
      },
   },
};
</script>
<style scoped>

.editbutton {
   position: absolute;
   top: 13px;
   color: #267eea;
   right: 20px;
}
.editbutton2 {
   position: absolute;
   top: 13px;
   color: #267eea;
   right: 52px;
}
</style>
