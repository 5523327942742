<template>
   <v-slide-x-reverse-transition>
      <div
         v-if="selfItem != null"
         class="sideForm pa-5 fill-height rounded-l-lg elevation-8"
         :class="{ sticktop: scrolled }"
      >
         <v-row>
            <v-col class="d-flex justify-end">
               <v-icon @click="closeForm"> mdi-close </v-icon>
            </v-col>
         </v-row>

         <div v-if="!selfItem.id" class="text-h6 black--text text-center">
            Add a new System
         </div>
         <div v-if="selfItem.id" class="text-h6 black--text text-center">
            Edit company ID:{{ selfItem.id }}
         </div>
         <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
               v-model="selfItem.title"
               label="System Title"
               required
            ></v-text-field>

            <v-text-field
               v-model="selfItem.short_desc"
               label="Short Desc"
               required
            ></v-text-field>

            <v-file-input
               accept="application/pdf"
               label="Select pdf"
               prepend-icon="mdi-image"
               v-model="tmpimg"
               @change="addFiles">
            </v-file-input>

            <v-col v-if="tmpimg || fileDat" sm="4">
               <v-img
                  :src="fileDat" ref="file"
               ></v-img>
            </v-col>

            <v-switch
               v-model="selfItem.is_active"
               :label="`is_active: ${selfItem.is_active.toString()}`"
            ></v-switch>

            <br />
            <v-btn :disabled="!valid" color="info" class="mr-4" @click="submit">
               Submit
            </v-btn>
            <v-btn @click="clear"> clear </v-btn>

         </v-form>
      </div>
   </v-slide-x-reverse-transition>
</template>

<script>

import SystemService from '@/service/SystemService.js';
import System from '@/models/System.js';
import util from '@/utils/';

export default {
   props: {
      systemItem: {
         type: Object,
         default: null,
      },
   },
   watch: {
      systemItem: function (val) {
         if (val != null) {
            this.selfItem = new System(val);
         } else {
            this.selfItem = null;
         }
         if (val) { this.fileDat = val.file_thumb; }
      },
   },
   data: () => ({
      fileDat: null,
      tmpimg: null,
      selfItem: null,
      valid: false,
      email: '',
      expDate: '',
      usersCount: '',
      logo: '',
      address: '',
      name: '',
      nameRules: [
         (v) => !!v || 'This field is required',
         (v) => v.length <= 50 || 'Name must be less than 20 characters',
      ],
      emailRules: [
         (v) => !!v || 'This field is required',
         (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      usersCountRules: [
         (v) => !!v || 'This field is required',
         (v) => v >= 1 || 'This field must ne a number',
      ],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      scrolled: false,
   }),
   methods: {
      addFiles() {
         this.readers = new FileReader();
         this.readers.onloadend = () => {
            const fileData = this.readers.result;
            this.fileDat = fileData;
            this.selfItem.file_svg = fileData;
         };
         this.readers.readAsDataURL(this.tmpimg);
      },
      submit() {
         if (this.$refs.form.validate()) {
            const systemService = new SystemService(this);
            if (!this.selfItem.id) {
               // is this a new item?
               systemService.add(this.selfItem).then((res) => {
                  if (res) {
                     this.closeForm();
                     util.showMessage('System added successfuly');
                  } else {
                     util.showMessage('Adding system failed', 'error');
                  }
               });
            } else {
               systemService.update(this.selfItem.id, this.selfItem).then((res) => {
                  if (res) {
                     this.closeForm();
                     util.showMessage('System updated successfuly');
                  } else {
                     util.showMessage('Updating system failed', 'error');
                  }
               });
            }
         }
      },
      clear() {
         this.$refs.form.reset();
      },
      closeForm() {
         this.selfItem = null;
         this.fileDat = null;
         this.tmpimg = null;
         this.$emit('update-value', null);
      },
      scrollListener: function () {
         if (window.scrollY > 65) { this.scrolled = true; } else { this.scrolled = false; }
      },
   },
   mounted: function () {
      window.addEventListener('scroll', this.scrollListener);
   },
   beforeDestroy: function () {
      window.removeEventListener('scroll', this.scrollListener);
   },
};
</script>
